<template>
	<div>
		<div class="js-user__content" style="">
			<div class="js-user-right">
				<h3 class="js-user-right-title">公告</h3>
				<p class="js-user-right-coonent">
					亲爱的专栏作者: <br />
					为提供更优使用体验，Meta
					Era将对平台的专栏进行系统升级，在重新开放前会暂停投稿功能，鉴于您过往的高质量产出，给您开通了特别投稿通道，可以在原有投稿界面继续使用投稿功能。目前仅针对小范围用户开放专栏投稿渠道，期待您的原创内容。
				</p>
				<!---->
				<!---->
			</div>
			<section class="js-article-page" style="">
				<div class="js-page" style="">
					<div style="">
						<div class="js-page-title">
							<h4>发布文章</h4>
							<h4 style="display: none">发布文章</h4>
							<a
								id="contribute-notice"
								href="javascript:;"
								class="js-page-title__tip"
								@click="dialogVisible = true"
							>
								投稿须知
								<img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAntJREFUSA29VkFrE0EUfm+2UWqL4MEeGtSzB7GXnqz1EItHQz2K4A/wLCWeDcVfIYjgRUmPEnNobBEUwUKhgjclOYiebCt2u/t8bzazjBMnziaLA8u+mfne9+3Mvpn3EALajfX9yzFhHYmuEcE8AFaBO0DQR4Q+IW5WkFov12Z3/kWHowC15kEdiJoEcHEUzswx2R4gNjqNmZYZc99/Fby+/vM8JelTXsKS6xDSR8AtjNTtV2vTn138kOBK8/BKCukL3rA5F1ykz1v9VYFabTdObdt+fwhmYtQhopM2aFwbEX8pwJotmgvqbUyTd5OuzP04WSmqaNFsrzIA/c8m3EbDZb9lAcJtxrSgRGNogCxcUPDs3rR+xA5pwq0jnsGZB4d+iKNgbi1W4OxppR+xg9tAQ6082l8IPWdC3t49hqNj0o/YoU00RGuKYqwDXxmhrfsxgTefDjU8TkK9MlyS4E1FSMvF3ADmz6B+ivrJ1TjFTtUijneXK3Bn6YR2ebJ1BI+7cRH3qiJCvozD26VzUQ627XxwhCFaYXFtkeQ3BY/ZtgXxm7ynCpH6fkTJM5zOZIW9kmn9dAg9jlLc9CNKnkHsqiiijZJpvXRSFaj2/dkP/PP3vChnwj7stu3AhrqiISVIFqVcFgwhPAPP38bw7UcKX76nsPG+wBkcaOSRXXt48Do0Y3i+xTssJUfnwcxVAeTnUGoQSZZerzEndAJmbuOeC0pGlhpEygIzOek7KzHUqsn2wpcLSkdqD6lBylipcLj1zJCgEZUaRPZd+uM0XSYyh108GZ48aMyA/f5vhbAtKrauCjhRD3Jn1WSYwT3cQ8IuVqglZ9r1dfu/AYNw6/z5auN0AAAAAElFTkSuQmCC"
							/></a>
						</div>
						<el-form
							label-position="right"
							label-width="120px"
							:model="formData"
							:rules="rule"
							ref="formData"
						>
							<el-form-item label="标题" prop="title">
								<el-input v-model="formData.title"></el-input>
							</el-form-item>
							<el-form-item label="摘要" prop="illustrate">
								<el-input v-model="formData.illustrate"></el-input>
							</el-form-item>
							<el-form-item label="封面图片" prop="pic">
								<el-upload
									class="upload-demo"
									action="#"
									:show-file-list="false"
									:on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload"
									:http-request="uploadImage"
									:drag="true"
								>
									<img v-if="formData.pic" :src="formData.pic" />
									<div v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text"
											>将封面图片拖到此处，或<em>点击上传</em></div
										>
									</div>
								</el-upload>
							</el-form-item>
							<el-form-item label="资讯内容" prop="content">
								<el-upload
									id="upimg"
									v-show="false"
									class="upload-demo"
									action="/upload"
									:on-success="handleSuccess"
									multiple
								>
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
								<quill-editor
									:options="editorOption"
									ref="QuillEditor"
									v-model="formData.content"
									@blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)"
									@ready="onEditorReady($event)"
								/>
							</el-form-item>

							<el-form-item label="发布时间" prop="release_time">
								<el-date-picker
									v-model="formData.release_time"
									value-format="yyyy-MM-dd HH:mm:ss"
									format="yyyy-MM-dd HH:mm:ss"
									type="datetime"
									placeholder=""
								>
								</el-date-picker>
							</el-form-item>

							<el-form-item>
								<div class="flex justify_content_c align_items_c">
									<el-button
										type="primary"
										class="custom_btn_width_100 pointer"
										@click="submit('formData')"
										v-if="$route.query.type != 'detail'"
										>保存</el-button
									>
									<el-button class="custom_btn_width_100 pointer" @click="back"
										>返回</el-button
									>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="js-article-result" style="display: none">
						<img src="https://staticn.jinse.cn/w/img/a8c0049.svg" alt="" />
						<p class="js-article-result__tip" style="display: none">
							提交成功，审核中…
						</p>
						<p class="js-article-result__tip" style="font-weight: 400">
							预计40~50分钟通过审核
						</p>
						<div class="wenzi">
							您的文章已提交审核，如需修改请审核通过后进行编辑；
						</div>
						<div class="qr-box">
							<div class="qr-left">
								在创作过程中遇到问题，可带着问题扫码添加工作人员微信：
							</div>
							<div class="qr-right-img">
								<img src="https://staticn.jinse.cn/w/img/bdbc09c.jpeg" />
							</div>
						</div>
						<p class="js-article-result__tip" style="display: none">
							草稿保存成功
						</p>
						<section
							class="js-article-result__option"
							style="margin-left: 65px"
						>
							<a
								href="/userColumn/articleList"
								class="js-button__primary js-button"
								>返回我的文章</a
							>
							<a
								id="article_go"
								href="javascript:void(0);"
								class="js-button__cancel js-button"
								>继续添加</a
							>
						</section>
					</div>
				</div>
				<div class="js-app">
					<div class="js-app-head">应用</div>
					<ul class="js-app-list">
						<li>
							<a href="javascript:;"
								><img
									src="https://staticn.jinse.cn/w/img/2fce1e6.png"
									alt=""
									class="icon"
								/>
								<img
									src="https://staticn.jinse.cn/w/img/5a61dd5.png"
									alt=""
									class="icon-active"
								/>
								<div class="name">投票</div>
							</a>
						</li>
					</ul>
					<!---->
				</div>
				<!---->
				<!---->
				<!---->
				<!---->
			</section>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<div class="js-dialog js-dialog-istext">
				<h4 class="js-dialog-title"> MetaEra网投稿须知 </h4>
				<a href="javascript:;" class="js-dialog-close">×</a>
				<div class="js-dialog-content">
					<section class="js-user-rule-main">
						<iframe
							:src="config.term_of_service.value"
							frameborder="0"
						></iframe>
					</section>
				</div>
				<div class="js-dialog-foot"
					><a
						href="javascript:;"
						class="js-button__primary js-button"
						@click="dialogVisible = false"
						>我已阅读并同意协议内容</a
					>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
const toolbarOptions = [
	["bold", "italic", "underline", "strike"], // toggled buttons
	["blockquote", "code-block"],

	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: "ordered" }, { list: "bullet" }],
	[{ script: "sub" }, { script: "super" }], // superscript/subscript
	[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
	[{ direction: "rtl" }], // text direction

	[{ size: ["small", false, "large", "huge"] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ font: [] }],
	[{ align: [] }],
	["link", "image", "video"],
	["clean"], // remove formatting button
];
import { myMixin } from "@/utils/mixin.js";
import {
	getCategoryList,
	addInformation,
	getLabelList,
	specialList,
} from "@/api/article";
import { uploadImage } from "@/api/user";
import { validateNull } from "@/utils/validate";
import { getAppConfig } from "@/api/home";

export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			dialogVisible: false,
			value: "",
			formData: {
				author_id: "",
				title: "",
				illustrate: "",
				language: "1",
				pic: "",
				content: "",
				release_time: "",
			},
			// 专题id
			specialId: "",
			// 资讯分类
			categoryList: [],
			// 配置
			config: {},
			// 标签列表
			labelList: [],
			// 资讯详情
			formationDetail: {},
			// 专题数据
			specialList: [],
			props: { multiple: false },
			rule: {
				category_id: [
					{ validator: validateNull, trigger: "change", required: true },
				],

				ticket_id: [
					{ validator: validateNull, trigger: "change", required: true },
				],

				title: [{ validator: validateNull, trigger: "blur", required: true }],
				illustrate: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				language: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				pic: [{ validator: validateNull, trigger: "change", required: true }],
				content: [{ validator: validateNull, trigger: "blur", required: true }],
				status: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				type: [{ validator: validateNull, trigger: "change", required: true }],

				release_time: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				score: [{ validator: validateNull, trigger: "change", required: true }],
			},
			editorOption: {
				modules: {
					toolbar: {
						container: toolbarOptions, // 工具栏
						handlers: {
							image: function (value) {
								if (value) {
									// 调用element的图片上传组件
									// （这里是用的原生js的选择dom方法）
									document.querySelector("#upimg button").click();
								} else {
									this.quill.format("image", false);
								}
							},
						},
					},
				},
			},
		};
	},
	created() {
		this.getAppConfig();
	},
	methods: {
		handleSuccess(res) {
			// 获取富文本组件实例
			let quill = this.$refs.QuillEditor.quill;
			if (res) {
				// 如果上传成功
				// 获取光标所在位置
				let length = quill.getSelection().index;
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(length, "image", res.data.url);
				// 调整光标到最后
				quill.setSelection(length + 1);
			} else {
				// 提示信息
				this.$message.error("图片插入失败");
			}
		},
		getAppConfig() {
			getAppConfig({})
				.then((res) => {
					if (res.code == 200) {
						this.config = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		// 资讯分类列表
		getCategoryListFun() {
			const that = this;
			getCategoryList({ size: 100 })
				.then((res) => {
					if (res.code == 200) {
						that.categoryList = res.data;
						// console.log(that.categoryList);
						that.categoryList.forEach((item) => {
							item.id = item.id.toString();
						});
					} else {
						that.categoryList = [];
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		// 上传图片
		uploadImage(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						// console.log(res.data.url);
						that.formData.pic = res.data.url;
					} else {
						// that.$message.error(res.message);
					}
				})
				.catch(function (error) {});
		},

		// 获取标签列表
		getLabelListFun() {
			const that = this;
			getLabelList({})
				.then((res) => {
					if (res.code == 200) {
						that.labelList = res.data;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		// 新增资讯提交
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const that = this;

					addInformation(this.formData)
						.then((res) => {
							if (res.code == 200) {
							} else {
								// that.$message.error(res.message);
							}
						})
						.catch((error) => {
							// that.$message.error(error.message);
						});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 获取专题数据
		getspecialList() {
			var childNodes = [];
			const that = this;
			specialList({})
				.then((res) => {
					if (res.code == 200) {
						that.specialList = that.toTree(res.data);
					} else {
						// that.$message.error(res.message);
					}
				})
				.catch((error) => {
					// that.$message.error(error.message);
				});
		},

		getLstInteger(str) {
			let lStrTemp = [];
			str.split(",").map((u) => {
				lStrTemp.push(parseInt(u));
			});
			lStrTemp.splice(lStrTemp.length - 1, 1);
			return lStrTemp;
		},
	},
};
</script>

<style scoped>
.js-loading {
	margin: 34px auto;
}

.js-loading .js-loadMoreBtn {
	width: 100%;
	height: 48px;
	border: 1px solid #f5f5f5;
	border-radius: 2px;
	background: #f5f5f5;
	text-align: center;
	cursor: pointer;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-noData {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-noData {
	flex-direction: column;
	min-height: 400px;
}

.js-loading .js-noData img {
	margin: 20px 0;
}

.js-loading .js-noData span {
	font-size: 15px;
	color: #999;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.js-no-more {
	text-align: center;
	font-size: 16px;
}

.js-menu {
	width: 260px;
	margin-right: 20px;
	background: #fff;
	padding: 43px 0;
	border-radius: 4px;
}

.js-menu li {
	margin-bottom: 40px;
}

.js-menu a.active,
.js-menu a:hover {
	color: #0056ff;
}

.js-menu .js-menu__nav1 {
	padding: 13px 0 13px 50px;
	display: flex;
	font-size: 20px;
	font-weight: 600;
	align-items: center;
}

.js-menu .js-menu__nav1 img {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.js-menu .js-menu__nav2 {
	display: block;
	padding: 13px 0 13px 85px;
	font-size: 16px;
	font-weight: 500;
}

.js-user-app {
	width: 100%;
	min-height: 90vh;
	height: auto;
	background-color: #f7f8fa;
}

.js-user-app .js-list-loading {
	margin-top: 300px;
}

.js-user-app .js-user {
	width: 1200px;
	min-height: 800px;
	margin: 30px auto 50px;
}

.js-user-app .js-user:after {
	content: "";
	display: block;
	visibility: hidden;
	height: 0;
	clear: both;
}

.js-user-app .js-user .js-user__menu {
	width: 260px;
	min-height: 992px;
	float: left;
	background: #fff;
}

.js-user-app .js-user .js-user__menu .chose-active {
	background: #f7f8fa;
	color: #000;
	font-weight: 700;
}

.js-user-app .js-user .js-user__menu .js-user__menu-avatar {
	width: 100px;
	height: 100px;
	margin: 61px auto;
}

.js-user-app .js-user .js-user__menu .js-user__menu-avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item {
	width: 260px;
	height: 50px;
	line-height: 50px;
	color: #333;
	font-size: 16px;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item li {
	padding-left: 80px;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item li:hover {
	cursor: pointer;
	background: #f7f8fa;
	color: #000;
	font-weight: 700;
}

.js-user-app
	.js-user
	.js-user__menu
	.js-user__menu-item
	li
	.js-user__menu-item_icon {
	width: 21px;
	height: 21px;
	vertical-align: middle;
}

.js-user-app .js-user .js-user__content {
	width: 920px;
	float: right;
	background: #fff;
	min-height: 992px;
}

.js-user-app .js-user .js-user__content .js-list-loading {
	margin-top: 300px;
}

.js-user-rights {
	display: flex;
	height: 130px;
	background: #f7f7fa;
	border-radius: 4px;
	padding: 0 0 20px;
}

.js-user-rights .js-user-right-coonents {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #333;
	padding: 0 13px;
	background: #fff4dd;
}

.js-user-rights .js-user-right-titles {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: #fff;
	height: 130px;
	background: #ffb100;
	border-radius: 4px;
	white-space: nowrap;
	padding: 0 13px;
}

.js-user-rights .js-user-right-img {
	width: 130px;
}

.js-user-right {
	display: flex;
	height: 70px;
	background: #f7f7fa;
	border-radius: 4px;
	padding: 0 0 20px;
}

.js-user-right .js-user-right-title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: #fff;
	height: 70px;
	background: #ffb100;
	border-radius: 4px;
	white-space: nowrap;
	padding: 0 13px;
}

.js-user-right .js-user-right-coonent {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #333;
	padding: 0 13px;
	background: #fff4dd;
}

.js-page-state {
	flex: 1;
}

.js-page-state .js-page-coonent {
	background: #fff;
	padding: 55px 0;
	border-radius: 20px;
	position: relative;
	left: 0;
	top: -40px;
}

.js-page {
	flex: 1;
	background: #fff;
	padding: 30px 40px 100px;
}

.js-page .js-page-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.js-page .js-page-head__title {
	font-size: 20px;
	color: #222;
	padding: 0;
}

.js-page .js-page-tip {
	font-size: 14px;
	color: #999;
	padding-bottom: 15px;
}

.js-page .js-page-content {
	padding: 50px 40px;
}

.js-page .js-page-tabs {
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5px 0;
	line-height: 30px;
}

.js-page .js-page-tabs:last-child {
	border-top: 0;
}

.js-page .js-page-tabs .js-page-tabs__label {
	font-size: 16px;
	min-width: 90px;
}

.js-page .js-page-tabs ul {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.js-page .js-page-tabs ul li {
	font-size: 14px;
	margin-right: 30px;
}

.js-page .js-page-tabs ul .active {
	color: #0056ff;
}

.js-page .js-page-tabs .js-page-tabs__more {
	width: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.js-page .js-page-assist {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f5f5f5;
	padding: 0 10px 0 16px;
	height: 44px;
	font-size: 16px;
}

.js-page .js-page-assist .js-page-assist_num {
	color: #f5a623;
	font-weight: 400;
}

.js-page .js-page-title {
	font-size: 20px;
	text-align: center;
	position: relative;

	margin-bottom: 20px;
}

.js-page .js-page-title__tip {
	position: absolute;
	top: 5px;
	right: 0;
	font-size: 12px;
	color: #468df8;
}

.js-page .js-page-title__tip img {
	width: 16px;
	height: 16px;
	vertical-align: bottom;
}

.js-button {
	font-size: 18px;
	width: auto;
	box-sizing: border-box;
	border-radius: 4px;
	height: 44px;
	padding: 3px 20px;
	margin-right: 40px;
}

.js-button__text {
	font-size: 16px;
	color: #0056ff;
}

.js-button__primary {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	color: #fff;
	border: 1px solid #0056ff;
}

.js-button__primary:hover {
	background: #fff;
	color: #0056ff;
}

.js-button__cancel {
	background: #fff;
	border: 1px solid #e1e1e1;
	font-size: 20px;
	color: #999;
}

.icon_up {
	border-bottom: 6px solid #333;
}

.icon_down,
.icon_up {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	margin: 4px;
}

.icon_down {
	border-top: 6px solid #333;
}

.show-simple {
	height: 30px;
	overflow: hidden;
}

.js-input {
	height: 30px;
}

.js-input-base,
.js-input {
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
}

.js-input-base {
	padding: 0 15px;
	flex: 1;
	font-size: 14px;
	min-height: 45px;
}

.js-input__icon {
	position: relative;
}

.js-input__icon .js-input {
	padding-left: 27px;
}

.js-input__icon .icon {
	position: absolute;
	top: 8px;
	left: 8px;
}

.js-input__error {
	border-color: red;
}

.js-checkbox {
	font-size: 16px;
}

.js-checkbox input {
	width: 15px;
	height: 15px;
}

.js-radio {
	font-size: 16px;
	display: flex;
	align-items: baseline;
}

.js-radio input {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #fff;
	border: 0.5px solid #ccc;
	box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 71%, 0.5);
	transform: translateY(4px);
	transition: border 0.3s ease-out;
}

.js-select {
	height: 45px;
	line-height: 45px;
	border: 1px solid #e1e1e1;
	padding-left: 15px;
	outline: 0;
	flex: 1;
}

.js-textarea {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 27px;
	height: auto;
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
	flex: 1;
}

.js-textarea:focus {
	outline: none;
}

.js-datepicker {
	margin-right: 20px;
}

.js-datepicker input {
	height: 44px;
	border-radius: 5px;
}

.js-back-view {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 1000;
	transition: all 0.5s;
	position: fixed;
}

.js-dialog {
	width: auto !important;
	height: 580px;
	background: #fff;
	border-radius: 4px;
	position: fixed;
	top: calc(50% - 330px);
	left: calc(50% - 440px);
	padding: 80px 40px 40px;
	overflow: hidden;
	position: relative;
}
.js-dialog iframe {
	width: 100%;
	height: 500px;
}
.js-dialog .js-dialog-title {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	font-size: 21px;
	font-weight: 500;
	color: #333;
	text-align: center;
}

.js-dialog .js-dialog-close {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 20px;
}

.js-dialog .js-dialog-operation {
	display: flex;
	justify-content: center;
}

.js-dialog .js-dialog-content {
	height: 100%;
}

.js-dialog .js-dialog-foot {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	text-align: center;
}

.js-dialog.js-dialog-istext {
	padding-bottom: 0px;
	width: 745px;
}

.js-dialog.js-dialog-istext .js-dialog-content {
	padding: 10px;
	background: hsla(0, 0%, 60%, 0.05);
	font-size: 16px;
	font-weight: 400;
	color: #333;
	line-height: 36px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w {
	padding-top: 15px;
	padding-bottom: 8px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w,
.js-dialog.js-dialog-istext .js-dialog-content p {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 36px;
}

.js-form li {
	display: flex;
	margin-bottom: 25px;
	line-height: 45px;
	font-weight: 500;
	color: #333;
	font-size: 14px;
	position: relative;
}

.js-form .js-form-label {
	font-size: 16px;
	min-width: 110px;
	margin-right: 20px;
}

.js-form .js-form-label .require {
	color: #ff001f;
}

.js-form .js-form-num {
	position: absolute;
	right: 40px;
	bottom: 0;
	color: #999;
}

.js-form .js-form-must {
	color: red;
}

.js-form .js-form-mul__list {
	display: flex;
}

.js-form .js-form-mul__list .js-form-mul__item {
	margin-right: 15px;
}

.js-form .js-form-mul__list .js-form-mul__item input {
	margin-right: 7px;
}

.js-form .js-form-img a {
	width: 270px;
	height: 169px;
	border-radius: 5px;
	background: #f5f5f5;
	display: block;
	text-align: center;
}

.js-form .js-form-img a:hover {
	background: #e6e6e6;
}

.js-form .js-form-img a section {
	padding-top: 70px;
}

.js-form .js-form-foot {
	justify-content: center;
}

.js-form .js-form__item-wrap {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.js-form .js-form__item-wrap .require {
	position: absolute;
	color: #ff001f;
	top: 5px;
	left: 10px;
}

.js-form .js-form__item-error {
	position: absolute;
	bottom: -32px;
}

.js-message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 9000;
	top: 40%;
	left: 50%;
	margin-left: -130px;
	padding: 20px;
	font-size: 30px;
	color: #fff;
	text-align: center;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
	width: 35px !important;
	line-height: 38px !important;
	height: 38px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
	background: url(https://staticn.jinse.cn/web/UEditor/themes/default/images/icons-n.png) -264px
		0 !important;
	background-size: 800px 30px !important;
	height: 38px !important;
	width: 19px !important;
	background-position: -311px 0 !important;
	border-left: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body {
	background: none !important;
	border: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body:hover {
	background: #fff !important;
}

.edui-default .edui-toolbar .edui-state-active .edui-splitborder {
	border: 0;
	width: 1px;
}

.js-page-state {
	flex: 1;
}

.js-page-state .js-page-coonent {
	background: #fff;
	padding: 55px 0;
	border-radius: 20px;
	position: relative;
	left: 0;
	top: -40px;
}

.js-page {
	flex: 1;
	background: #fff;
	padding: 30px 40px 100px;
}

.js-page .js-page-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.js-page .js-page-head__title {
	font-size: 20px;
	color: #222;
	padding: 0;
}

.js-page .js-page-tip {
	font-size: 14px;
	color: #999;
	padding-bottom: 15px;
}

.js-page .js-page-content {
	padding: 50px 40px;
}

.js-page .js-page-tabs {
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5px 0;
	line-height: 30px;
}

.js-page .js-page-tabs:last-child {
	border-top: 0;
}

.js-page .js-page-tabs .js-page-tabs__label {
	font-size: 16px;
	min-width: 90px;
}

.js-page .js-page-tabs ul {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.js-page .js-page-tabs ul li {
	font-size: 14px;
	margin-right: 30px;
}

.js-page .js-page-tabs ul .active {
	color: #0056ff;
}

.js-page .js-page-tabs .js-page-tabs__more {
	width: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.js-page .js-page-assist {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f5f5f5;
	padding: 0 10px 0 16px;
	height: 44px;
	font-size: 16px;
}

.js-page .js-page-assist .js-page-assist_num {
	color: #f5a623;
	font-weight: 400;
}

.js-page .js-page-title {
	font-size: 20px;
	text-align: center;
	position: relative;
}

.js-page .js-page-title__tip {
	position: absolute;
	top: 5px;
	right: 0;
	font-size: 12px;
	color: #468df8;
}

.js-page .js-page-title__tip img {
	width: 16px;
	height: 16px;
	vertical-align: bottom;
}

.js-button {
	font-size: 18px;
	width: auto;
	box-sizing: border-box;
	border-radius: 4px;
	height: 44px;
	padding: 3px 20px;
	margin-right: 40px;
}

.js-button__text {
	font-size: 16px;
	color: #0056ff;
}

.js-button__primary {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	color: #fff;
	border: 1px solid #0056ff;
}

.js-button__primary:hover {
	background: #fff;
	color: #0056ff;
}

.js-button__cancel {
	background: #fff;
	border: 1px solid #e1e1e1;
	font-size: 20px;
	color: #999;
}

.icon_up {
	border-bottom: 6px solid #333;
}

.icon_down,
.icon_up {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	margin: 4px;
}

.icon_down {
	border-top: 6px solid #333;
}

.show-simple {
	height: 30px;
	overflow: hidden;
}

.js-input {
	height: 30px;
}

.js-input-base,
.js-input {
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
}

.js-input-base {
	padding: 0 15px;
	flex: 1;
	font-size: 14px;
	min-height: 45px;
}

.js-input__icon {
	position: relative;
}

.js-input__icon .js-input {
	padding-left: 27px;
}

.js-input__icon .icon {
	position: absolute;
	top: 8px;
	left: 8px;
}

.js-input__error {
	border-color: red;
}

.js-checkbox {
	font-size: 16px;
}

.js-checkbox input {
	width: 15px;
	height: 15px;
}

.js-radio {
	font-size: 16px;
	display: flex;
	align-items: baseline;
}

.js-radio input {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #fff;
	border: 0.5px solid #ccc;
	box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 71%, 0.5);
	transform: translateY(4px);
	transition: border 0.3s ease-out;
}

.js-select {
	height: 45px;
	line-height: 45px;
	border: 1px solid #e1e1e1;
	padding-left: 15px;
	outline: 0;
	flex: 1;
}

.js-textarea {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 27px;
	height: auto;
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
	flex: 1;
}

.js-textarea:focus {
	outline: none;
}

.js-datepicker {
	margin-right: 20px;
}

.js-datepicker input {
	height: 44px;
	border-radius: 5px;
}

.js-back-view {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 1000;
	transition: all 0.5s;
	position: fixed;
}

.js-dialog {
	width: 800px;
	height: 580px;
	background: #fff;
	border-radius: 4px;
	position: fixed;
	top: calc(50% - 330px);
	left: calc(50% - 440px);
	padding: 80px 40px 40px;
	overflow: hidden;
	position: relative;
}

.js-dialog .js-dialog-title {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	font-size: 21px;
	font-weight: 500;
	color: #333;
	text-align: center;
}

.js-dialog .js-dialog-close {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 20px;
}

.js-dialog .js-dialog-operation {
	display: flex;
	justify-content: center;
}

.js-dialog .js-dialog-content {
	height: 100%;
}

.js-dialog .js-dialog-foot {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	text-align: center;
}

.js-dialog.js-dialog-istext {
	padding-bottom: 25px;
	width: 745px;
}

.js-dialog.js-dialog-istext .js-dialog-content {
	padding: 10px;
	background: hsla(0, 0%, 60%, 0.05);
	font-size: 16px;
	font-weight: 400;
	color: #333;
	line-height: 36px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w {
	padding-top: 15px;
	padding-bottom: 8px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w,
.js-dialog.js-dialog-istext .js-dialog-content p {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 36px;
}

.js-form li {
	display: flex;
	margin-bottom: 25px;
	line-height: 45px;
	font-weight: 500;
	color: #333;
	font-size: 14px;
	position: relative;
}

.js-form .js-form-label {
	font-size: 16px;
	min-width: 110px;
	margin-right: 20px;
}

.js-form .js-form-label .require {
	color: #ff001f;
}

.js-form .js-form-num {
	position: absolute;
	right: 40px;
	bottom: 0;
	color: #999;
}

.js-form .js-form-must {
	color: red;
}

.js-form .js-form-mul__list {
	display: flex;
}

.js-form .js-form-mul__list .js-form-mul__item {
	margin-right: 15px;
}

.js-form .js-form-mul__list .js-form-mul__item input {
	margin-right: 7px;
}

.js-form .js-form-img a {
	width: 270px;
	height: 169px;
	border-radius: 5px;
	background: #f5f5f5;
	display: block;
	text-align: center;
}

.js-form .js-form-img a:hover {
	background: #e6e6e6;
}

.js-form .js-form-img a section {
	padding-top: 70px;
}

.js-form .js-form-foot {
	justify-content: center;
}

.js-form .js-form__item-wrap {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.js-form .js-form__item-wrap .require {
	position: absolute;
	color: #ff001f;
	top: 5px;
	left: 10px;
}

.js-form .js-form__item-error {
	position: absolute;
	bottom: -32px;
}

.js-message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 9000;
	top: 40%;
	left: 50%;
	margin-left: -130px;
	padding: 20px;
	font-size: 30px;
	color: #fff;
	text-align: center;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
	width: 35px !important;
	line-height: 38px !important;
	height: 38px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
	background: url(https://staticn.jinse.cn/web/UEditor/themes/default/images/icons-n.png) -264px
		0 !important;
	background-size: 800px 30px !important;
	height: 38px !important;
	width: 19px !important;
	background-position: -311px 0 !important;
	border-left: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body {
	background: none !important;
	border: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body:hover {
	background: #fff !important;
}

.edui-default .edui-toolbar .edui-state-active .edui-splitborder {
	border: 0;
	width: 1px;
}

.js-page-state {
	flex: 1;
}

.js-page-state .js-page-coonent {
	background: #fff;
	padding: 55px 0;
	border-radius: 20px;
	position: relative;
	left: 0;
	top: -40px;
}

.js-page {
	flex: 1;
	background: #fff;
	padding: 30px 40px 100px;
}

.js-page .js-page-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.js-page .js-page-head__title {
	font-size: 20px;
	color: #222;
	padding: 0;
}

.js-page .js-page-tip {
	font-size: 14px;
	color: #999;
	padding-bottom: 15px;
}

.js-page .js-page-content {
	padding: 50px 40px;
}

.js-page .js-page-tabs {
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5px 0;
	line-height: 30px;
}

.js-page .js-page-tabs:last-child {
	border-top: 0;
}

.js-page .js-page-tabs .js-page-tabs__label {
	font-size: 16px;
	min-width: 90px;
}

.js-page .js-page-tabs ul {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.js-page .js-page-tabs ul li {
	font-size: 14px;
	margin-right: 30px;
}

.js-page .js-page-tabs ul .active {
	color: #0056ff;
}

.js-page .js-page-tabs .js-page-tabs__more {
	width: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.js-page .js-page-assist {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f5f5f5;
	padding: 0 10px 0 16px;
	height: 44px;
	font-size: 16px;
}

.js-page .js-page-assist .js-page-assist_num {
	color: #f5a623;
	font-weight: 400;
}

.js-page .js-page-title {
	font-size: 20px;
	text-align: center;
	position: relative;
}

.js-page .js-page-title__tip {
	position: absolute;
	top: 5px;
	right: 0;
	font-size: 12px;
	color: #468df8;
}

.js-page .js-page-title__tip img {
	width: 16px;
	height: 16px;
	vertical-align: bottom;
}

.js-button {
	font-size: 18px;
	width: auto;
	box-sizing: border-box;
	border-radius: 4px;
	height: 44px;
	padding: 3px 20px;
	margin-right: 40px;
}

.js-button__text {
	font-size: 16px;
	color: #0056ff;
}

.js-button__primary {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	color: #fff;
	border: 1px solid #0056ff;
}

.js-button__primary:hover {
	background: #fff;
	color: #0056ff;
}

.js-button__cancel {
	background: #fff;
	border: 1px solid #e1e1e1;
	font-size: 20px;
	color: #999;
}

.icon_up {
	border-bottom: 6px solid #333;
}

.icon_down,
.icon_up {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	margin: 4px;
}

.icon_down {
	border-top: 6px solid #333;
}

.show-simple {
	height: 30px;
	overflow: hidden;
}

.js-input {
	height: 30px;
}

.js-input-base,
.js-input {
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
}

.js-input-base {
	padding: 0 15px;
	flex: 1;
	font-size: 14px;
	min-height: 45px;
}

.js-input__icon {
	position: relative;
}

.js-input__icon .js-input {
	padding-left: 27px;
}

.js-input__icon .icon {
	position: absolute;
	top: 8px;
	left: 8px;
}

.js-input__error {
	border-color: red;
}

.js-checkbox {
	font-size: 16px;
}

.js-checkbox input {
	width: 15px;
	height: 15px;
}

.js-radio {
	font-size: 16px;
	display: flex;
	align-items: baseline;
}

.js-radio input {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #fff;
	border: 0.5px solid #ccc;
	box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 71%, 0.5);
	transform: translateY(4px);
	transition: border 0.3s ease-out;
}

.js-select {
	height: 45px;
	line-height: 45px;
	border: 1px solid #e1e1e1;
	padding-left: 15px;
	outline: 0;
	flex: 1;
}

.js-textarea {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 27px;
	height: auto;
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
	flex: 1;
}

.js-textarea:focus {
	outline: none;
}

.js-datepicker {
	margin-right: 20px;
}

.js-datepicker input {
	height: 44px;
	border-radius: 5px;
}

.js-back-view {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 1000;
	transition: all 0.5s;
	position: fixed;
}

.js-dialog {
	width: 800px;
	height: 580px;
	background: #fff;
	border-radius: 4px;
	position: fixed;
	top: calc(50% - 330px);
	left: calc(50% - 440px);
	padding: 80px 40px 40px;
	overflow: hidden;
	position: relative;
}

.js-dialog .js-dialog-title {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	font-size: 21px;
	font-weight: 500;
	color: #333;
	text-align: center;
}

.js-dialog .js-dialog-close {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 20px;
}

.js-dialog .js-dialog-operation {
	display: flex;
	justify-content: center;
}

.js-dialog .js-dialog-content {
	height: 100%;
}

.js-dialog .js-dialog-foot {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	text-align: center;
}

.js-dialog.js-dialog-istext {
	padding-bottom: 25px;
	width: 745px;
}

.js-dialog.js-dialog-istext .js-dialog-content {
	padding: 10px;
	background: hsla(0, 0%, 60%, 0.05);
	font-size: 16px;
	font-weight: 400;
	color: #333;
	line-height: 36px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w {
	padding-top: 15px;
	padding-bottom: 8px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w,
.js-dialog.js-dialog-istext .js-dialog-content p {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 36px;
}

.js-form li {
	display: flex;
	margin-bottom: 25px;
	line-height: 45px;
	font-weight: 500;
	color: #333;
	font-size: 14px;
	position: relative;
}

.js-form .js-form-label {
	font-size: 16px;
	min-width: 110px;
	margin-right: 20px;
}

.js-form .js-form-label .require {
	color: #ff001f;
}

.js-form .js-form-num {
	position: absolute;
	right: 40px;
	bottom: 0;
	color: #999;
}

.js-form .js-form-must {
	color: red;
}

.js-form .js-form-mul__list {
	display: flex;
}

.js-form .js-form-mul__list .js-form-mul__item {
	margin-right: 15px;
}

.js-form .js-form-mul__list .js-form-mul__item input {
	margin-right: 7px;
}

.js-form .js-form-img a {
	width: 270px;
	height: 169px;
	border-radius: 5px;
	background: #f5f5f5;
	display: block;
	text-align: center;
}

.js-form .js-form-img a:hover {
	background: #e6e6e6;
}

.js-form .js-form-img a section {
	padding-top: 70px;
}

.js-form .js-form-foot {
	justify-content: center;
}

.js-form .js-form__item-wrap {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.js-form .js-form__item-wrap .require {
	position: absolute;
	color: #ff001f;
	top: 5px;
	left: 10px;
}

.js-form .js-form__item-error {
	position: absolute;
	bottom: -32px;
}

.js-message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 9000;
	top: 40%;
	left: 50%;
	margin-left: -130px;
	padding: 20px;
	font-size: 30px;
	color: #fff;
	text-align: center;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
	width: 35px !important;
	line-height: 38px !important;
	height: 38px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
	background: url(https://staticn.jinse.cn/web/UEditor/themes/default/images/icons-n.png) -264px
		0 !important;
	background-size: 800px 30px !important;
	height: 38px !important;
	width: 19px !important;
	background-position: -311px 0 !important;
	border-left: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body {
	background: none !important;
	border: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body:hover {
	background: #fff !important;
}

.edui-default .edui-toolbar .edui-state-active .edui-splitborder {
	border: 0;
	width: 1px;
}

.js-dialog.js-dialog-istext {
	width: 810px;
	background: #fff;
	border-radius: 20px;
}

.js-dialog.js-dialog-istext .js-dialog-close {
	width: 44px;
	height: 44px;
	top: -22px;
	right: -22px;
	font-size: 0;
}

.js-dialog.js-dialog-istext .js-dialog-content {
	background-color: #fff;
	border-top: 1px solid #e1e1e1;
}

.js-dialog.js-dialog-istext .js-button__primary {
	background-color: #ffb100;
	border: 1px solid #ffb100;
}

.js-dialog.js-dialog-istext .js-button__primary:hover {
	color: #ffb100;
	background-color: #fff;
}

.js-button__disabled {
	background-color: #dcdcdc;
	border: 1px solid #dcdcdc;
	color: #fff;
	pointer-events: none;
}

.js-button__disabled:hover {
	cursor: not-allowed;
}

.js-loading {
	margin: 34px auto;
}

.js-loading .js-loadMoreBtn {
	width: 100%;
	height: 48px;
	border: 1px solid #f5f5f5;
	border-radius: 2px;
	background: #f5f5f5;
	text-align: center;
	cursor: pointer;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-noData {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-noData {
	flex-direction: column;
	min-height: 400px;
}

.js-loading .js-noData img {
	margin: 20px 0;
}

.js-loading .js-noData span {
	font-size: 15px;
	color: #999;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.js-no-more {
	text-align: center;
	font-size: 16px;
}

.js-vote {
	padding: 20px 20px 13px;
	background: #f9f9f9;
	opacity: 0.8;
	margin: 10px 0 20px;
}

.js-vote .js-vote__head {
	font-size: 16px;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.js-vote .js-vote__head .js-vote__head-close {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.js-vote .js-vote__option {
	margin: 8px 0;
	position: relative;
	border-radius: 4px;
	padding: 8px 0;
	color: #767680;
	font-size: 14px;
	line-height: 14px;
	background: #fff;
	width: 100%;
	text-align: center;
}

.js-app {
	min-width: 70px;
	background: #fff;
	margin-left: 10px;
}

.js-app .js-app-head {
	font-size: 17px;
	font-weight: 600;
	text-align: center;
	padding: 13px 0;
	border-bottom: 3px solid #f7f8fa;
}

.js-app .js-app-list {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.js-app .js-app-list li {
	margin-top: 18px;
}

.js-app .js-app-list li a {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #000;
}

.js-app .js-app-list li a img {
	width: 30px;
	height: 30px;
	margin-bottom: 4px;
}

.js-app .js-app-list li a .name {
	font-size: 15px;
}

.js-app .js-app-list li a .icon-active {
	display: none;
}

.js-app .js-app-list li a:hover {
	color: #ffb100;
}

.js-app .js-app-list li a:hover .icon {
	display: none;
}

.js-app .js-app-list li a:hover .icon-active {
	display: block;
}

.js-page-state {
	flex: 1;
}

.js-page-state .js-page-coonent {
	background: #fff;
	padding: 55px 0;
	border-radius: 20px;
	position: relative;
	left: 0;
	top: -40px;
}

.js-page {
	flex: 1;
	background: #fff;
	padding: 30px 40px 100px;
}

.js-page .js-page-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.js-page .js-page-head__title {
	font-size: 20px;
	color: #222;
	padding: 0;
}

.js-page .js-page-tip {
	font-size: 14px;
	color: #999;
	padding-bottom: 15px;
}

.js-page .js-page-content {
	padding: 50px 40px;
}

.js-page .js-page-tabs {
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 5px 0;
	line-height: 30px;
}

.js-page .js-page-tabs:last-child {
	border-top: 0;
}

.js-page .js-page-tabs .js-page-tabs__label {
	font-size: 16px;
	min-width: 90px;
}

.js-page .js-page-tabs ul {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.js-page .js-page-tabs ul li {
	font-size: 14px;
	margin-right: 30px;
}

.js-page .js-page-tabs ul .active {
	color: #0056ff;
}

.js-page .js-page-tabs .js-page-tabs__more {
	width: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.js-page .js-page-assist {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f5f5f5;
	padding: 0 10px 0 16px;
	height: 44px;
	font-size: 16px;
}

.js-page .js-page-assist .js-page-assist_num {
	color: #f5a623;
	font-weight: 400;
}

.js-page .js-page-title {
	font-size: 20px;
	text-align: center;
	position: relative;
}

.js-page .js-page-title__tip {
	position: absolute;
	top: 5px;
	right: 0;
	font-size: 12px;
	color: #468df8;
}

.js-page .js-page-title__tip img {
	width: 16px;
	height: 16px;
	vertical-align: bottom;
}

.js-button {
	font-size: 18px;
	box-sizing: border-box;
	border-radius: 4px;
	height: 44px;
	padding: 3px 20px;
	margin-right: 40px;
}

.js-button__text {
	font-size: 16px;
	color: #0056ff;
}

.js-button__primary {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	color: #fff;
	border: 1px solid #0056ff;
}

.js-button__primary:hover {
	background: #fff;
	color: #0056ff;
}

.js-button__cancel {
	background: #fff;
	border: 1px solid #e1e1e1;
	font-size: 20px;
	color: #999;
}

.icon_up {
	border-bottom: 6px solid #333;
}

.icon_down,
.icon_up {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	margin: 4px;
}

.icon_down {
	border-top: 6px solid #333;
}

.show-simple {
	height: 30px;
	overflow: hidden;
}

.js-input {
	height: 30px;
}

.js-input-base,
.js-input {
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
}

.js-input-base {
	padding: 0 15px;
	flex: 1;
	font-size: 14px;
	min-height: 45px;
}

.js-input__icon {
	position: relative;
}

.js-input__icon .js-input {
	padding-left: 27px;
}

.js-input__icon .icon {
	position: absolute;
	top: 8px;
	left: 8px;
}

.js-input__error {
	border-color: red;
}

.js-checkbox {
	font-size: 16px;
}

.js-checkbox input {
	width: 15px;
	height: 15px;
}

.js-radio {
	font-size: 16px;
	display: flex;
	align-items: baseline;
}

.js-radio input {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #fff;
	border: 0.5px solid #ccc;
	box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 71%, 0.5);
	transform: translateY(4px);
	transition: border 0.3s ease-out;
}

.js-select {
	height: 45px;
	line-height: 45px;
	border: 1px solid #e1e1e1;
	padding-left: 15px;
	outline: 0;
	flex: 1;
}

.js-textarea {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 27px;
	height: auto;
	background: #fff;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	transition: border 0.3s;
	flex: 1;
}

.js-textarea:focus {
	outline: none;
}

.js-datepicker {
	margin-right: 20px;
}

.js-datepicker input {
	height: 44px;
	border-radius: 5px;
}

.js-back-view {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	z-index: 1000;
	transition: all 0.5s;
	position: fixed;
}

.js-dialog {
	width: 800px;
	height: 580px;
	background: #fff;
	border-radius: 4px;
	position: fixed;
	top: calc(50% - 330px);
	left: calc(50% - 440px);
	padding: 80px 40px 40px;
	overflow: hidden;
	position: relative;
}

.js-dialog .js-dialog-title {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	font-size: 21px;
	font-weight: 500;
	color: #333;
	text-align: center;
}

.js-dialog .js-dialog-close {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 20px;
}

.js-dialog .js-dialog-operation {
	display: flex;
	justify-content: center;
}

.js-dialog .js-dialog-content {
	height: 100%;
}

.js-dialog .js-dialog-foot {
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	text-align: center;
}

.js-dialog.js-dialog-istext {
	padding-bottom: 25px;
	width: 745px;
}

.js-dialog.js-dialog-istext .js-dialog-content {
	padding: 10px;
	background: hsla(0, 0%, 60%, 0.05);
	font-size: 16px;
	font-weight: 400;
	color: #333;
	line-height: 36px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w {
	padding-top: 15px;
	padding-bottom: 8px;
}

.js-dialog.js-dialog-istext .js-dialog-content .font-w,
.js-dialog.js-dialog-istext .js-dialog-content p {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	line-height: 36px;
}

.js-form li {
	display: flex;
	margin-bottom: 25px;
	line-height: 45px;
	font-weight: 500;
	color: #333;
	font-size: 14px;
	position: relative;
}

.js-form .js-form-label {
	font-size: 16px;
	min-width: 110px;
	margin-right: 20px;
}

.js-form .js-form-label .require {
	color: #ff001f;
}

.js-form .js-form-num {
	position: absolute;
	right: 40px;
	bottom: 0;
	color: #999;
}

.js-form .js-form-must {
	color: red;
}

.js-form .js-form-mul__list {
	display: flex;
}

.js-form .js-form-mul__list .js-form-mul__item {
	margin-right: 15px;
}

.js-form .js-form-mul__list .js-form-mul__item input {
	margin-right: 7px;
}

.js-form .js-form-img a {
	width: 270px;
	height: 169px;
	border-radius: 5px;
	background: #f5f5f5;
	display: block;
	text-align: center;
}

.js-form .js-form-img a:hover {
	background: #e6e6e6;
}

.js-form .js-form-img a section {
	padding-top: 70px;
}

.js-form .js-form-foot {
	justify-content: center;
}

.js-form .js-form__item-wrap {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}

.js-form .js-form__item-wrap .require {
	position: absolute;
	color: #ff001f;
	top: 5px;
	left: 10px;
}

.js-form .js-form__item-error {
	position: absolute;
	bottom: -32px;
}

.js-message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 9000;
	top: 40%;
	left: 50%;
	margin-left: -130px;
	padding: 20px;
	font-size: 30px;
	color: #fff;
	text-align: center;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
	width: 35px !important;
	line-height: 38px !important;
	height: 38px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
	background: url(https://staticn.jinse.cn/web/UEditor/themes/default/images/icons-n.png) -264px
		0 !important;
	background-size: 800px 30px !important;
	height: 38px !important;
	width: 19px !important;
	background-position: -311px 0 !important;
	border-left: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body {
	background: none !important;
	border: 0 !important;
}

.edui-default .edui-toolbar .edui-combox .edui-combox-body:hover {
	background: #fff !important;
}

.edui-default .edui-toolbar .edui-state-active .edui-splitborder {
	border: 0;
	width: 1px;
}

.js-form-label__tip {
	width: 17px;
	height: 17px;
	border-radius: 9px;
	color: #f39700;
	border: 1px solid #f39700;
	position: relative;
	display: inline-block;
	line-height: 18px;
	text-align: center;
}

.js-form-label__tip .js-form-label__tip-content {
	display: none;
	position: absolute;
	background: #fff;
	border: 2px solid #ddd;
	top: 27px;
	left: 0;
	z-index: 100;
	width: 600px;
}

.js-form-label__tip .js-form-label__tip-content img {
	width: 100%;
}

.js-form-label__tip:hover .js-form-label__tip-content {
	display: block;
}

.js-button {
	width: auto;
	padding: 3px 30px;
}

.js-save-tip {
	padding: 8px 12px;
	line-height: 30px;
	background: #888;
	color: #fff;
	margin-left: 70px;
}

.js-article-result {
	padding-top: 187px;
	padding-bottom: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.js-article-result img {
	width: 64px;
	height: 63px;
	margin-bottom: 64px;
}

.js-article-result p {
	font-size: 20px;
	color: #333;
	font-weight: 600;
	margin-bottom: 18px;
}

.js-article-result .wenzi {
	margin-bottom: 10px;
}

.js-article-result .qr-box,
.js-article-result .wenzi {
	font-size: 13px;
	font-family: PingFangSC-Regular, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
}

.js-article-result .qr-box {
	width: 330px;
	height: 61px;
	margin-bottom: 50px;
	display: flex;
}

.js-article-result .qr-box .qr-left {
	width: 250px;
	height: 61px;
	flex: 1;
}

.js-article-result .qr-box .qr-right-img {
	margin-left: 20px;
	height: 61px;
	width: 61px;
}

.js-article-page {
	display: flex;
	background: #f6f7f9;
}

.js-page {
	width: 750px;
}

.js-textarea {
	width: 530px;
	flex: auto;
}

#editor {
	width: 758px;
	min-height: 380px;
	margin-bottom: 15px;
}

.toastBox {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	width: 400px;
	position: fixed;
	z-index: 9000;
	top: 40%;
	left: 50%;
	margin-left: -130px;
	padding: 20px;
	text-align: center;
	color: #fff;
	font-size: 30px;
}

.base-disabled-btn {
	margin-right: 40px;
	padding: 12px 38px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	transition: all 0.5s ease-out;
	border: 1px solid #0056ff;
}

.quill-editor {
	width: 100%;
}
</style>
